.ScrollIndicator {
    position: absolute;
    bottom: 300px;
    right: 20px;
    background: white;
    border: 1px solid black;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: slideIn 0.5s forwards;
    cursor: pointer;
    z-index: 1000;
}

@keyframes slideIn {
    from {
        right: -100%;
        transform: translateX(-100%);
    }

    to {
        /*This will change for each element */
        right: 30px;
        transform: translateX(0%);
    }
}

