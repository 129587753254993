.rdrMonthAndYearPickers {
  display: block !important;
}

.schedule-visit-calendar .react-calendar__month-view__weekdays {
  border-bottom: 2px solid #1e88e5;
}

.schedule-visit-calendar .react-calendar__month-view__weekdays__weekday {
  height: 30px;
}
.schedule-visit-calendar .react-calendar__month-view__weekdays__weekday abbr {
  color: #1e88e5;
}

.schedule-visit-calendar .react-calendar__tile:hover {
  background-color: rgb(242, 242, 223);
}

.schedule-visit-calendar .react-calendar__tile {
  height: 60px;
}

.schedule-visit-calendar .react-calendar__tile.react-calendar__tile--active {
  border-radius: unset;
}

.schedule-visit-calendar
  .react-calendar__tile.react-calendar__tile--active:hover {
  background-color: #1e88e5;
}

.schedule-visit-calendar .react-calendar__navigation__arrow {
  color: #1e88e5;
  font-size: 24px;
}

.giphy-gif-img {
  cursor: pointer !important;
}

.giphy-search-bar {
  margin-bottom: 10px;
  border: 1px solid black !important;
  border-right: 0 !important;
}

/* .quill {
  .ql-container {
    height: 89%;
    border: none;
  }
} */
/* .delaership-dropsdown {
  background: #000;
} */

.garageSwipper .swiper-button-prev {
  color: #000;
  background-color: #fff;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  position: absolute;
  box-shadow: 0 2px 6px 0 hsla(0, 0%, 43.9%, 0.6);
  opacity: 0.9;
  cursor: pointer;
}
.garageSwipper .swiper-button-prev::after {
  font-size: 16px;
}

.garageSwipper .swiper-button-next {
  color: #000;
  background-color: #fff;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  box-shadow: 0 2px 6px 0 hsla(0, 0%, 43.9%, 0.6);
  opacity: 0.9;
  cursor: pointer;
}
.garageSwipper .swiper-button-next::after {
  font-size: 16px;
}

.applyForCreditIframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.iframeContainer {
  padding-top: 75%;
}

.emailBodyContent a {
  text-decoration: underline;
  color: #06c;
}

.emailInboxSearch .chakra-input__left-element {
  height: 2.2rem;
}

.messageHistory a {
  text-decoration: underline;
  color: #06c;
}
.usageInfoTable td {
  white-space: nowrap;
}

.smsCenterBody .ql-toolbar.ql-snow {
  display: none;
}

.rdrDayHovered {
  overflow: hidden;
}
.rdrStartEdge {
  left: 0;
  right: 0;
}
.rdrEndEdge {
  right: 0;
}

.tooltip {
  position: relative;
  overflow: visible;
}
.tooltip::before {
  content: "";
  position: absolute;
  background: red;
  clip-path: polygon(100% 0, 0 0, 50% 100%);
  width: 1.5rem;
  height: 1.5rem;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}
/* .apexcharts-tooltip.apexcharts-theme-dark {
  background: transparent !important;
} */
.apexcharts-tooltip {
  overflow: visible !important;
}

.ql-editor-wrapper img {
  height: 150px;

  aspect-ratio: 3/2;
}
.ql-editor img:not(.emoji) {
  max-width: 100%; /* Ensures images are responsive */
  height: auto; /* Maintain aspect ratio */
  max-height: 150px;
  aspect-ratio: 3/2;
  display: inline-block; /* Keeps images inline but allows control */
}

/* Emoji images inside Quill editor */
.ql-editor img.emoji {
  width: 1em !important;
  height: 1em !important;
  max-width: 1em !important;
  max-height: 1em !important;
  vertical-align: middle;
  display: inline !important;
}

.ql-editor p {
  display: block; /* Resets any flex behavior */
  line-height: 1.5; /* Keeps readability */
  word-wrap: break-word;
  white-space: normal; /* Ensures normal text wrapping */
}
.ql-editor span {
  display: inline-block;
}
.ql-editor-wrapper p {
  /* display: flex; */
  /* gap: 1rem; */
  align-items: flex-start;
  flex-wrap: wrap;
  line-break: anywhere;
}

/* General image styling, excluding the translate icon */
.emailComposeWrapper img:not(.translateIcon img) {
  max-width: 100%;
  height: auto;
}

/* Regular images (excluding emojis & translate icons) */
.emailComposeWrapper
  img:not([alt*="emoji"]):not([src*="twemoji"]):not([src*="emojipedia"]):not(
    [src*="emoji"]
  ):not([src*="googleapis"]):not(.translateIcon img) {
  height: 150px;
  max-width: calc(24% - 0.5rem) !important;
  width: 100%;
  object-fit: cover;
  aspect-ratio: 3/2;
}

/* Emoji images */
.emailComposeWrapper img[alt*="emoji"],
.emailComposeWrapper img[src*="twemoji"],
.emailComposeWrapper img[src*="emojipedia"],
.emailComposeWrapper img[src*="emoji"],
.emailComposeWrapper img[src*="googleapis"] {
  height: 20px !important;
  width: 20px !important;
  object-fit: contain;
  vertical-align: middle;
}

/* Ensure the translate icon remains unaffected */
.emailComposeWrapper .translateIcon img {
  max-width: 30px !important;
  width: auto;
  height: auto;
  aspect-ratio: unset !important;
  object-fit: unset !important;
}

.emailComposeWrapper.createEmailTemplate .translateIcon img {
  max-width: 30px !important;
  width: 100%;
  height: 100%;
  aspect-ratio: unset !important;
  object-fit: unset !important;
}

.attachmentViewerWrapper .input-wrapper button img {
  max-width: 30px !important;
  width: 100%;
  height: 100%;
  aspect-ratio: unset !important;
  object-fit: unset !important;
}

.emailComposeWrapper.dmsEquityMiningEditor .translateIcon button img {
  max-width: 30px !important;
  width: 100%;
  height: 100%;
  aspect-ratio: unset !important;
  object-fit: unset !important;
}

.dmsEquityMiningEditor .translateIcon button img {
  max-width: 30px !important;
  width: 100%;
  height: 100%;
  aspect-ratio: unset !important;
  object-fit: unset !important;
}

.attachmentViewerWrapper .translateIcon {
  max-width: 30px !important;
  width: 100%;
  height: 100%;
  aspect-ratio: unset !important;
  object-fit: unset !important;
}

.attachmentViewer img {
  width: 100px;
  height: 80px;
}

.emailComposeWrapper p {
  /* display: flex;
  gap: 1rem; */
  align-items: flex-start;
  flex-wrap: wrap;
  white-space: wrap;
  line-break: anywhere;
}
.emailComposeWrapper p img {
  margin-right: 0.5rem;
}

.announcementEmailBody p {
  white-space: wrap;
  max-width: 100%;

  line-break: anywhere;
}

.announcementEmailBody .ql-blank {
  min-height: calc(-850px + 100vh);
}

/* calender */

.singleDatePicker .rdrMonthAndYearPickers {
  display: block !important;
}
.singleDatePicker .rdrMonth {
  width: 290px;
}

/* gif */
.gif-searchbar .giphy-search-bar {
  border: 2px solid #e2e8f0;
  overflow: hidden;
}

.calenderDatePicker .react-datepicker-wrapper {
  width: 100% !important;
}

/* message input */

.emailMsgEditor .input-wrapper {
  min-height: 100%;
  height: 100%;
}
.emailMsgEditor .chakra-input__group {
  min-height: 100%;
  height: 100%;
}

/* location date picker */

.locationDatePicker input:focus-visible {
  border: none !important;
  outline: none !important;
}

.locationDatePicker input {
  cursor: pointer !important;
}

/* attachment viewer */

.attachmentViewerWrapper img {
  width: 100px;
  height: 100px;
}

/* template body */

.templateBody img {
  width: 200px;
  height: 150px;
  margin-top: 12px;
}
.templateBody p {
  line-break: anywhere;
}

/* dms equity minig */
.dmsEquityMiningEditor .ql-container {
  height: 230px;
  overflow: auto;
}

/* email template */
.createEmailTemplate .ql-container {
  height: 300px;
  overflow: auto;
}

.emailComposeDMS .ql-container {
  height: 100%;
  padding-bottom: 42px;
}

.emailComposeDMS .quill {
  overflow: hidden !important;
}

/* email activity content */
.emailActivityContent img {
  width: 134px;
  height: 120px;
  margin-top: 12px;
}

.hideScrollbar.createEmailTemplate .ql-container {
  min-height: calc(100vh - 550px) !important;
  height: 100%;
  overflow: unset !important;
  /* height: unset !important; */
}

.hideScrollbar .ql-editor {
  min-height: 400px;
}
.past-date {
  color: gray !important;
  pointer-events: none;
  background-color: #f0f0f0; /* Optional: Add a subtle background color for clarity */
  cursor: not-allowed; /* Show not-allowed cursor on hover */
}

.timePickerWrapper .react-datepicker-wrapper {
  width: 100%;
}
.intercom-launcher {
  bottom: 50px !important;
  /* right: 50px !important; */
}

/* big calendar */

.rbc-month-row {
  overflow: unset !important;
}
